import Matrix from './Matrix';
import Image from './img';

function App() {
  return (
    <div>
      <div class="relative">
        <Matrix fullscreen={true} color={'#008000'} />
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Image />
      </div>
    </div>
  );
}

export default App;
