import React from 'react';
import imgStyle from "./img.css";

import hackerman from './img/hackerman.jpg';
import hackThePlanet from './img/hackThePlanet.jpg';
import floppy from './img/floppy.gif';
import hacker1 from './img/hacker1.gif';
import hacker2 from './img/hacker2.gif';
import linux from './img/linux.jpg';
import anonymous from './img/anonymous.gif';
import hackerEnergy from './img/hacker_energy.jpg';
import noice from './img/noice.jpg';

export default class Image extends React.Component {
    static images = [
        hackerman,
        hackThePlanet,
        floppy,
        hacker1,
        hacker2,
        linux,
        anonymous,
        hackerEnergy,
        noice
    ]

    constructor(props) {
        super(props);

        let index = Math.floor(Math.random()*Image.images.length);

        this.state = {
            index: index,
            selected: Image.images[index]
        };
        this.selectImage = this.selectImage.bind(this);
    }

    selectImage() {
        let index = this.state.index;
        if (index + 1 >= Image.images.length) {
            index = 0;
        } 
        else {
            index += 1;
        }

        this.setState({
            index: index,
            selected: Image.images[index]
        }); 
    }

    render () {
        return (
            <img 
                src={this.state.selected}
                onClick={this.selectImage}
                className={imgStyle.img}
                alt=""
            />
        );
    }
}
